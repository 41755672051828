<template>
  <div ref="sectionRef" class="bg-neutral-50 py-10 md:py-15">
    <div class="grid-default px-page">
      <div class="col-span-12 md:col-span-4">
        <Heading v-if="data.title" generic :size="3" class="mb-2 max-w-[457px] md:mb-14">
          {{ data.title }}
        </Heading>
        <div v-if="Array.isArray(data.link) && data.link.length">
          <Button
            :label="data.link[0].label"
            :to="
              data.link[0].internalLink !== null
                ? localePathByType(
                    data?.link[0].internalLink!.__typename as string,
                    data?.link[0].internalLink!.slug as string
                  )
                : data.link[0].externalLink
            "
            :target="data.link[0].externalLink ? '_blank' : undefined"
            variant="link"
            :icon="{
              name: 'circle-chevron-right-general'
            }"
          />
        </div>
      </div>
      <div class="col-span-12 md:col-span-8">
        <div class="flex justify-end pt-16 md:pt-8">
          <Dropdown v-model="state.range" :options="rangeOptions($t)" />
        </div>
      </div>
    </div>
    <div class="md:px-page h-[500px] md:h-[560px]">
      <LazyCryptoMarketPricesChart
        v-if="crypto && isSectionVisible"
        :crypto="crypto.id"
        :crypto-name="crypto.name"
        :crypto-image="crypto.image"
        :range="state.range"
        :loading="{ color: 'neutral' }"
        :error="{ color: 'neutral' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import { SectionGraphRecord } from '~/types/generated'
import { DEFAULT_CRYPTO, DEFAULT_DATE_TIME_RANGE, rangeOptions } from '~/data/cryptoMarket'
import { useCryptoMarketStore } from '~/stores/CryptoMarketStore'

defineProps({
  data: {
    type: Object as PropType<SectionGraphRecord>,
    default: () => {}
  }
})

const { localePathByType } = useLocalePathByType()
const cryptoMarketStore = useCryptoMarketStore()
const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const state = reactive({
  range: DEFAULT_DATE_TIME_RANGE
})

const crypto = computed(() => cryptoMarketStore.getCoins(DEFAULT_CRYPTO)[0])

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
